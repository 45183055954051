<template>
  <div>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/project/cutting-he/histori"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Edit Data
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div v-if="dataOri">
                <ValidationObserver ref="formAdd">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">No. SE</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Nomor Surat Edaran" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-se-1" 
                                  label-for="input-cuttinghe-1"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-cuttinghe-1"
                                  variant="solid"
                                  size="lg"
                                  :options="opsSE"
                                  v-model="form.no_se"
                                  required
                                  :state="valid"
                                  >
                                  </b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Minggu</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Minggu" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-se-2" 
                                  label-for="input-cuttinghe-2"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-cuttinghe-2"
                                  variant="solid"
                                  size="lg"
                                  :options="opsSEPerminggu"
                                  v-model="form.no_se_minggu"
                                  required
                                  :state="valid"
                                  >
                                  </b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Hatchery</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Hatchery" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-se-3" 
                                  label-for="input-cuttinghe-3"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-cuttinghe-3"
                                  variant="solid"
                                  size="lg"
                                  :options="opsHatchery"
                                  v-model="form.hatchery"
                                  required
                                  :state="valid"
                                  >
                                  </b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">No.BA</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Nomor Berita Acara" rules="required|max:50" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-se-4" 
                                  label-for="input-cuttinghe-4"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                    id="input-cuttinghe-4"
                                    v-model="form.no_ba"
                                    class="form-control form-control-solid"
                                    required
                                    size="lg"
                                    placeholder="Nomor Berita Acara"
                                    :invalid-feedback="errors[0]"
                                    :state="valid"
                                  ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Tgl. Cutting</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Tanggal Cutting" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-se-5" 
                                  label-for="input-cuttinghe-5"
                                  :invalid-feedback="errors[0]"
                                  description="pilih tanggal sesuai yang ada di berita acara."
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-datepicker
                                    id="input-cuttinghe-5"
                                    class="form-control form-control-solid pr-0"
                                    v-model="form.tgl_cutting"
                                    size="lg"
                                    required
                                    placeholder="Tanggal Cutting"
                                    :state="valid"
                                  ></b-form-datepicker>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <b-row v-for="(rinc, index) in form.rincian" :key="index">
                    <b-col cols="10">
                      <h5 class="mt-5 text-muted">Rincian {{index + 1}}</h5>
                    </b-col>
                    <b-col cols="2" class="text-right">
                      <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="mt-5 text-hover-primary">
                        <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                      </b-button>
                    </b-col>
                    <b-col cols="12" md="6" sm="12">
                      <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ errors, valid }">
                        <b-form-group 
                        :id="`input-group-${index}-6`" 
                        label="Sex:" 
                        :label-for="`input-${index}-6`"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                          <b-form-select2
                          :id="`input-${index}-6`"
                          variant="solid"
                          size="lg"
                          :options="opsSex"
                          v-model="rinc.sex"
                          required
                          :state="valid">
                          </b-form-select2>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                    <b-col cols="12" md="6" sm="12">
                      <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                        <b-form-group 
                        :id="`input-group-${index}-7`" 
                        label="Jumlah:" 
                        :label-for="`input-${index}-7`"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                          <vue-number-input 
                            :attrs="{ id: `input-${index}-7`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                            v-model="rinc.jumlah"
                            :min="0"
                            :max="9999999999"
                            size="large"
                            required
                            center
                            controls
                          ></vue-number-input>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                  </b-row>

                  <b-row class="mb-5">
                    <b-col cols="12" class="text-right">
                      <b-button @click="addRincian" variant="link" class="mt-5 text-hover-primary">
                        <i class="flaticon2-plus-1 p-0"></i>
                        Add Rincian
                      </b-button>
                    </b-col>
                  </b-row>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">File Berita Acara</label>
                      <div class="col-lg-9 col-xl-7">
                        <a v-if="tempAttachments.length > 0" @click="removefiles" class="btn btn-light-primary font-weight-bold">Remove files</a>
                        <vue-dropzone
                          ref="myVueDropzone"
                          :include-styling="false"
                          :useCustomSlot="true"
                          id="dropzone"
                          @vdropzone-file-added="fileAdded"
                          @vdropzone-upload-progress="uploadProgress"
                          @vdropzone-success="uploadSuccess"
                          @vdropzone-error="uploadError"
                          :options="dropzoneOptions"
                        >
                          <a v-if="tempAttachments.length == 0" class="btn btn-light-primary font-weight-bold">Attach files</a>
                          <span v-else></span>
                        </vue-dropzone>
                        <span class="form-text text-muted">Ukuran file Maksimal 1MB (MegaByte) </span>

                        <div v-if="tempAttachments.length > 0" class="mt-3">  
                          <div v-for="(item, key) in tempAttachments" :key="key" class="alert alert-light" :class="{'cursor-pointer': !item.message}" @click="downloadPdf(item)">
                            <p> {{ item.title }} ({{ (item.size/1000000).toFixed(2) }} MB) </p>
                            <b-progress height="7px" :value="item.progress" :max="100" show-progress animated></b-progress>
                            <span v-if="item.message" class="form-text text-danger">{{ item.message }}</span>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div class="mt-10">
                    <button 
                    @click="onSubmit" 
                    ref="kt_submit"
                    class="btn btn-primary font-weight-bold">
                      <i class="la la-save"></i>
                      Perbarui
                    </button>

                    &nbsp;

                    <button
                    @click="onReset"
                    class="btn btn-clean font-weight-bold">
                      <i class="la la-recycle"></i>
                      Reset
                    </button>
                  </div>
                  
                </b-form>
                </ValidationObserver>           
              </div>          
              <div v-else>
                <BulletListLoader></BulletListLoader>
              </div> 
            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import CuttingHEService from "@/core/services/api/data/cuttinghe.service";
import ErrorService from "@/core/services/error.service";
import JwtService from "@/core/services/jwt.service";

import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "project-cutting-he-edit",
  components: {
    vueDropzone: vue2Dropzone,
    BulletListLoader
  },
  props: {
    idBA: {
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      dataOri: null,
      daload: true,
      form: {
        id: this.idBA,
        no_se: null,
        no_se_minggu: null,
        hatchery: null,
        no_ba: null,
        tgl_cutting: new Date().toISOString().slice(0,10),
        rincian: [{
          jumlah: 0,
          sex: null
        }]
      },
      tempAttachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL }/v2/data/cutting-he/file`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        maxFilesize: 1,
        maxFiles: 1,
        acceptedFiles: 'application/pdf',
        includeStyling: false,
        previewsContainer: false,
        parallelUploads: 20
      },
      opsSE: [
        { text: 'Silahkan pilih Surat Edaran', value: null },
      ],
      opsSEPerminggu: [
        { text: 'Silahkan pilih Minggu', value: null },
      ],
      opsHatchery: [
        { text: 'Silahkan pilih Hatchery', value: null },
      ],
      opsSex: [
        { text: 'Silakan pilih Sex', value: null }, 
      ],
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    cekRincian () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.sex] = ++a[e.sex] || 0;
        return a;
      }, {});

      return this.opsSex.filter(e => lookup[e.value] && e.value !== null)
    },
    listRincian () {
        if (this.cekRincian.length > 0) {
            return this.cekRincian.map(function(item) {return ` ${item['text']}`})
        }
        return ''
    }
  },  
  watch: {
    'form.no_se' (val,old) {
      if (val != old) {
          if(!this.daload) {
            this.form.no_se_minggu = null
          }
          this.getSEPerminggu(val)
      }
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    
    addRincian(){
      this.form.rincian.push({
        jumlah: 0,
        sex: null
      })
    },
    deleteRincian(index){
      if (this.form.rincian.length > 1) {
        this.form.rincian.splice(index,1);
      }
      else {
        this.form.rincian = [
          {
            jumlah: 0,
            sex: null
          }
        ]
      }
    },      
    getSE() {
      CuttingHEService.getCuttingHESE()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSE = [{
                  value: null,
                  text: "Select Surat Edaran"
                }]
                return;
            }

            this.opsSE = res.data.data
            this.opsSE.push({
              value: null,
              text: "Select Surat Edaran"
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    getSEPerminggu(value) {
      if (!value) {
        return
      }
      this.daload = false 
      
      CuttingHEService.getCuttingHESEPerminggu({se: value})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSEPerminggu = [{
                  value: null,
                  text: "Silahkan pilih Minggu"
                }]
                return;
            }

            this.opsSEPerminggu = res.data.data
            this.opsSEPerminggu.push({
              value: null,
              text: "Silahkan pilih Minggu"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsSEPerminggu = [{
              value: null,
              text: "Tidak ada data"
            }]
        })
    },
    getHatchery() {
      PerusahaanService.getPerusahaanHatchery()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsHatchery = [{
                  value: null,
                  text: "Select Hatchery"
                }]
                return;
            }

            this.opsHatchery = res.data.data
            this.opsHatchery.push({
              value: null,
              text: "Select Hatchery"
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    getSex() {
      MasterService.getTernakSex()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSex = [{
                  value: null,
                  text: "Silakan pilih Sex"
                }]
                return;
            }

            this.opsSex = res.data.data
            this.opsSex.push({
              value: null,
              text: "Silakan pilih Sex"
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    getData () {
      CuttingHEService.getCuttingHEEdit({ _id: this.idBA })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data

            let attachment = {};
            attachment._id = res.data.data.file;
            attachment.title = res.data.data.file;
            attachment.type = "file";
            attachment.extension = ".pdf";
            attachment.isLoading = true;
            attachment.progress = 100;
            attachment.filename = res.data.data.file;
            attachment.size = 1000000;
            attachment.thumb = false;
            attachment.message = null;
            this.tempAttachments = [...this.tempAttachments, attachment];
        })
        .catch((err) => ErrorService.status(err))
    },
    removefiles () {
        this.$refs.myVueDropzone.removeAllFiles(true);
        this.form.file = null
        this.tempAttachments= [];
    },
    fileAdded(file) {      
      let attachment = {};
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = "file";
      attachment.extension = "." + file.type.split("/")[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      attachment.message = null;
      this.tempAttachments = [...this.tempAttachments, attachment];

    },
    uploadProgress(file, progress, bytesSent) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`;
        }
      });
    },
    uploadSuccess(file, response) {
      this.form.file = response.data

      ErrorService.message(response)
    },
    uploadError(file, message, xhr) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.message = message;
        }
      });
    },
    downloadPdf(file) {
      if (file.filename && file.progress == 100 && !file.message) {
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/cutting-he/thum/${file.filename}`, "_blank"); 
      }
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (!this.form.file) {
          ErrorService.message({
          action: 'Peringatan',
          message: 'Upload file berita acara untuk menyimpan data',
        })
          return;
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Rincian data harus diisi',
            })
            return;
        }

        if (this.cekRincian.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: `Double input Rincian: ${this.listRincian}`,
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            CuttingHEService.updateCuttingHE(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.dataOri = JSON.stringify(this.form)
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = JSON.parse(this.dataOri)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cutting HE" }]);
    if (!this.currentUser.menu.cutting_he) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getSE()
    this.getHatchery()
    this.getSex()
    this.getData()
  },
};
</script>
